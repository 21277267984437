import { type Instructor, type InstructorParams } from "shared/types/instructor"
import { api } from "./axios"
import { API_ENDPOINTS } from "./config"
import { type AxiosResponse } from "axios"
import { type ErrorResponse } from "../types/common"

// ----------------------------------------------------------------------

export function getInstructors(params?: Partial<InstructorParams>): Promise<AxiosResponse<{
  totalCount: number
  instructors: Array<Instructor>
}, ErrorResponse>> {
  return api.get(API_ENDPOINTS.instructors, { params })
}

export function getInstructorById(instructorId: string): Promise<AxiosResponse<Instructor, ErrorResponse>> {
  return api.get(`${API_ENDPOINTS.instructors}/${instructorId}`)
}

export function toggleInstructorPublish(instructorId: string, published: boolean): Promise<AxiosResponse<Instructor, ErrorResponse>> {
  return api.patch(`${API_ENDPOINTS.instructors}/${instructorId}`, { published })
}

export function update(instructorId: string, body: FormData): Promise<AxiosResponse<Instructor, ErrorResponse>> {
  return api.patch(`${API_ENDPOINTS.instructors}/${instructorId}`, body)
}

export function create(body: FormData): Promise<AxiosResponse<Instructor, ErrorResponse>> {
  return api.post(API_ENDPOINTS.instructors, body)
}

export function remove(instructorId: string): Promise<AxiosResponse<unknown, ErrorResponse>> {
  return api.delete(`${API_ENDPOINTS.instructors}/${instructorId}`)
}

export function updateMany(list: Array<Partial<Instructor>>): Promise<AxiosResponse<unknown, ErrorResponse>> {
  return api.post(`${API_ENDPOINTS.instructors}/update-many`, { list })
}
