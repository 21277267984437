import {
  type ScheduleEventParams,
  type ScheduleEvent, type ScheduleEventRequest,
} from "shared/types/scheduleEvent"
import { api } from "./axios"
import { API_ENDPOINTS } from "./config"
import { type AxiosResponse } from "axios"
import { type ErrorResponse } from "../types/common"

// ----------------------------------------------------------------------

export function getScheduleEvents(params?: Partial<ScheduleEventParams>): Promise<AxiosResponse<{
  totalCount: number
  scheduleEvents: Array<ScheduleEvent>
}, ErrorResponse>> {
  return api.get(API_ENDPOINTS.scheduleEvents, { params })
}

export function getScheduleEventById(scheduleEventId: string): Promise<AxiosResponse<ScheduleEvent, ErrorResponse>> {
  return api.get(`${API_ENDPOINTS.scheduleEvents}/${scheduleEventId}`)
}

export function update(scheduleEventId: string, body: ScheduleEventRequest): Promise<AxiosResponse<ScheduleEvent, ErrorResponse>> {
  return api.patch(`${API_ENDPOINTS.scheduleEvents}/${scheduleEventId}`, body)
}

export function create(body: ScheduleEventRequest): Promise<AxiosResponse<ScheduleEvent, ErrorResponse>> {
  return api.post(API_ENDPOINTS.scheduleEvents, body)
}

export function remove(scheduleEventId: string): Promise<AxiosResponse<unknown, ErrorResponse>> {
  return api.delete(`${API_ENDPOINTS.scheduleEvents}/${scheduleEventId}`)
}
