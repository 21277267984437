import { observer } from "mobx-react-lite"
import React, { useCallback, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { PATHS } from "shared/lib/paths"
import { AuthStore } from "../store"

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: React.ReactNode
};

export const GuestGuard: React.FC<GuestGuardProps> = observer(({ children }) => {
  const navigate = useNavigate()

  const { isAuthenticated } = AuthStore

  const check = useCallback(() => {
    if (isAuthenticated) {
      const searchParams = new URLSearchParams(window.location.search)
      const returnTo = searchParams.get("returnTo")

      navigate(returnTo ? new URL(returnTo).pathname : PATHS.DASHBOARD.INSTRUCTORS.ROOT, { replace: true })
    }
  }, [ isAuthenticated, navigate ])

  useEffect(() => {
    check()
  }, [ check ])

  return <>{children}</>
})
