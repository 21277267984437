import { api } from "./axios"
import { API_ENDPOINTS } from "./config"
import { type AxiosResponse } from "axios"
import { type Banner } from "../types/banner"
import { type ErrorResponse } from "../types/common"

// ----------------------------------------------------------------------

export function getAll(): Promise<AxiosResponse<{ totalCount: number, banners: Array<Banner> }, ErrorResponse>> {
  return api.get(API_ENDPOINTS.banners)
}

export function getById(bannerId: string): Promise<AxiosResponse<Banner, ErrorResponse>> {
  return api.get(`${API_ENDPOINTS.banners}/${bannerId}`)
}

export function getActive(): Promise<AxiosResponse<Banner | null, ErrorResponse>> {
  return api.get(`${API_ENDPOINTS.banners}/active`)
}

export function setActive(bannerId: string, state: boolean): Promise<AxiosResponse<{
  oldActive: Banner | null
  newActive: Banner
}, ErrorResponse>> {
  return api.post(`${API_ENDPOINTS.banners}/active`, { bannerId, state })
}

export function update(bannerId: string, body: FormData): Promise<AxiosResponse<Banner, ErrorResponse>> {
  return api.patch(`${API_ENDPOINTS.banners}/${bannerId}`, body)
}

export function create(body: FormData): Promise<AxiosResponse<Banner>> {
  return api.post(API_ENDPOINTS.banners, body)
}

export function remove(bannerId: string): Promise<AxiosResponse<unknown>> {
  return api.delete(`${API_ENDPOINTS.banners}/${bannerId}`)
}
