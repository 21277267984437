import React, { useEffect, useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import { PATHS } from "shared/lib/paths"
import { observer } from "mobx-react-lite"
import { AuthStore } from "../store"

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: React.ReactNode
};

export const AuthGuard: React.FC<AuthGuardProps> = observer(({ children }) => {
  const navigate = useNavigate()
  const { isAuthenticated } = AuthStore
  const [ checked, setChecked ] = useState(false)

  const check = useCallback(() => {
    if (!isAuthenticated) {
      const searchParams = new URLSearchParams({ returnTo: window.location.href }).toString()
      const href = `${PATHS.AUTH.LOGIN}?${searchParams}`

      navigate(href, { replace: true })
    } else {
      setChecked(true)
    }
  }, [ isAuthenticated, navigate ])

  useEffect(() => {
    check()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isAuthenticated ])

  if (!checked) {
    return null
  }

  return <>{children}</>
})
