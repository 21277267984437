import { lazy } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { CompactLayout } from "../layouts/compact"

// ----------------------------------------------------------------------

export const Page404 = lazy(() => import("pages").then(module => ({ default: module.Page404 })))
export const Page403 = lazy(() => import("pages").then(module => ({ default: module.Page403 })))
export const Page500 = lazy(() => import("pages").then(module => ({ default: module.Page500 })))

// ----------------------------------------------------------------------

export const errorRoutes = [
  {
    path: "/error",
    element: (
      <CompactLayout>
        <Outlet/>
      </CompactLayout>
    ),
    children: [
      { element: <Navigate to="/" replace/>, index: true },
      { path: "500", element: <Page500/> },
      { path: "404", element: <Page404/> },
      { path: "403", element: <Page403/> },
    ],
  },
]
