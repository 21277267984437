import { makeAutoObservable, runInAction } from "mobx"
import { umbrellaException } from "../../shared/lib/umbrellaException"
import {
  type AboutOrganization,
  type AboutOrganizationPageTag,
  type AboutOrganizationRequest,
} from "../../shared/types/aboutOrganization"
import { getAboutOrganizationData, updateAboutOrganizationData } from "../../shared/api/aboutOrganization"

// ----------------------------------------------------------------------

class AboutStoreImpl {
  data: AboutOrganization | null = null
  error: string | null = null
  isLoading: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  loadData = async (_pageTag: AboutOrganizationPageTag): Promise<AboutOrganization | undefined> => {
    try {
      runInAction(() => {
        this.isLoading = true
        this.error = null
      })

      const { data } = await getAboutOrganizationData(_pageTag)

      runInAction(() => {
        this.data = data
        this.isLoading = false
      })
      return data
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }

  updatePageData = async (_pageTag: AboutOrganizationPageTag, data: AboutOrganizationRequest): Promise<boolean> => {
    try {
      this.isLoading = true
      this.error = null

      await updateAboutOrganizationData(_pageTag, data)

      runInAction(() => {
        this.isLoading = false
      })

      return true
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })

      return false
    }
  }
}

export const AboutStore = new AboutStoreImpl()
