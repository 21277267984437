import { observer } from "mobx-react-lite"
import { createStyles, Button, Burger, keyframes, Drawer, type MantineColor } from "@mantine/core"
import React, { useState } from "react"
import { Link } from "react-scroll"
import { useMediaQuery, useWindowScroll } from "@mantine/hooks"
import { LandingLogo } from "../../component"
import { type LandingDto } from "../../../model"

export const menuItems = [
  { href: "program", label: "Программа" },
  { href: "team", label: "Преподаватели" },
  { href: "prices", label: "Стоимость" },
  { href: "licence", label: "Образовательная лицензия" },
]

export const emphasize = keyframes({
  "from": { visibility: "hidden", width: 0 },
  "to": { visibility: "visible", width: "100%" },
})

const useStyles = createStyles((theme, { primaryColor, secondaryColor }: {
  primaryColor: MantineColor
  secondaryColor: MantineColor
}) => ({
  wrapper: {
    height: 90,
    position: "fixed",
    left: 0,
    top: 0,
    right: 0,
    transition: "all 0.3s",
    zIndex: 1000,
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent",
  },

  wrapperShift: {
    background: theme.fn.linearGradient(90, theme.fn.rgba(theme.colors[secondaryColor][7], 0.92), theme.fn.rgba(theme.colors[primaryColor][7], 0.92)),
    height: 70,

    "&::before": {
      content: "''",
      backgroundImage: "url(assets/team-back.png)",
      backgroundAttachment: "fixed",
      backgroundPosition: "top center",
      backgroundSize: "cover",
      position: "absolute",
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      zIndex: -1,
      opacity: 0.1,
    },
  },

  container: {
    maxWidth: 1320,
    display: "flex",
    alignItems: "center",
    width: "100%",
    paddingRight: theme.spacing.md,
    paddingLeft: theme.spacing.md,
    marginRight: "auto",
    marginLeft: "auto",
  },

  logo: {
    marginRight: "auto",
  },

  navigation: {
    display: "flex",
    gap: theme.spacing.xl,
  },

  drawerNavigation: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.xl,
    paddingTop: theme.spacing.xl,
  },

  button: {
    position: "relative",
    cursor: "pointer",
    color: theme.black,
    fontWeight: 700,
    transition: "0.3s ease-in-out",
    paddingTop: "10px",
    paddingBottom: "10px",
    textDecoration: "none",

    "&:hover": {
      "&::before": {
        content: "\"\"",
        position: "absolute",
        width: 0,
        height: 2,
        bottom: 0,
        left: 0,
        backgroundColor: theme.colors[secondaryColor][7],
        visibility: "hidden",
        transition: "all 0.3s ease-in-out 0s",
        animation: `${emphasize} 0.3s ease-in-out forwards`,
      },
    },
  },

  drawerButton: {
    cursor: "pointer",
    color: theme.black,
    fontWeight: 700,
    transition: "0.3s ease-in-out",
    fontSize: 18,
  },

  drawerButtonActive: {
    color: theme.colors[secondaryColor][7],
  },

  buttonActive: {
    "&::before": {
      content: "\"\"",
      position: "absolute",
      width: 0,
      height: 2,
      bottom: 0,
      left: 0,
      backgroundColor: theme.colors[secondaryColor][7],
      visibility: "hidden",
      transition: "all 0.3s ease-in-out 0s",
      animation: `${emphasize} 0.3s ease-in-out forwards`,
    },
  },

  buttonActiveShift: {
    "&::before": {
      content: "\"\"",
      backgroundColor: theme.white,
    },
  },

  buttonShift: {
    paddingTop: "5px",
    paddingBottom: "5px",
    color: theme.white,

    "&:hover": {
      "&::before": {
        content: "\"\"",
        backgroundColor: theme.white,
      },
    },
  },

  drawerBody: {
    background: "#f1f1f1",
    overflow: "hidden",
    position: "relative",
    zIndex: 0,

    "&::before": {
      content: "''",
      width: "100%",
      height: "100%",
      backgroundImage: "url(/assets/funky-lines.webp)",
      backgroundAttachment: "fixed",
      backgroundPosition: "top center",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: -1,
      opacity: 0.1,
    },
  },
}))

type Props = {
  landing: LandingDto
  landingColors: {
    primary: MantineColor
    secondary: MantineColor
  }
}

export const LandingSectionMenu = observer(function LandingSectionMenu(props: Props) {
  const {
    landing,
    landingColors,
  } = props
  const { classes, cx } = useStyles(
    {
      primaryColor: landingColors.primary,
      secondaryColor: landingColors.secondary,
    })
  const isMd = useMediaQuery("(max-width: 1080px)")

  const [ scroll ] = useWindowScroll()
  const [ opened, setIsOpened ] = useState(false)

  const condition = scroll.y >= 100

  const toggle = () => setIsOpened(prev => !prev)
  const close = () => setIsOpened(false)

  const label = opened ? "Закрыть меню" : "Открыть меню"

  return (
    <>
      <header className={cx(classes.wrapper, { [classes.wrapperShift]: condition })}>
        <div className={classes.container}>
          <LandingLogo className={classes.logo}/>

          {!isMd && <nav className={classes.navigation}>
            {menuItems.map(({ label, href }, index) => (
              <Link
                hashSpy={true}
                spy={true}
                activeClass={cx(classes.buttonActive, { [classes.buttonActiveShift]: condition })}
                className={cx(classes.button, { [classes.buttonShift]: condition })}
                key={index}
                to={href}
                smooth={true}
                duration={1000}
                offset={-70}
                href={`#${href}`}
              >
                {label}
              </Link>
            ))}
          </nav>}

          <Button
            variant={condition ? "light" : "gradient"}
            ml="xl"
            radius="lg"
            gradient={{ from: `${landingColors.secondary}.7`, to: `${landingColors.primary}.7`, deg: 105 }}
            size={isMd ? "xs" : "md"}
            // onClick={() => FormModalStore.setData({ target: "Профессия: Нутрициолог. Блок: Меню. Кнопка: Оставить заявку" })}
          >
            Заказать звонок
          </Button>

          {isMd && <Burger color={condition ? "white" : "black"} ml="xl" opened={opened} onClick={toggle}
                           aria-label={label}/>}
        </div>
      </header>

      <Drawer.Root position="right" zIndex={1050} opened={opened} onClose={close}>
        <Drawer.Overlay/>
        <Drawer.Content className={classes.drawerBody}>
          <Drawer.Header pt={32} px={32} pb={32}>
            <Drawer.Title fz={24} fw={900}>Навигация</Drawer.Title>
            <Drawer.CloseButton radius="lg" size="lg"/>
          </Drawer.Header>
          <Drawer.Body p={32}>
            <nav className={classes.drawerNavigation}>
              {menuItems.map(({ label, href }, index) => (
                <Link
                  key={index}
                  hashSpy={true}
                  spy={true}
                  activeClass={classes.drawerButtonActive}
                  className={classes.drawerButton}
                  to={href}
                  smooth={true}
                  duration={1000}
                  onClick={close}
                  offset={-70}
                >
                  {label}
                </Link>
              ))}
            </nav>
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>
    </>
  )
})
