import { createStyles } from "@mantine/core"
import { Link } from "react-router-dom"
import logo from "shared/assets/logo.svg"
import React from "react"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  link: {
    height: 44,
  },

  image: {
    maxHeight: 44,
    objectFit: "contain",
    verticalAlign: "middle",
  },
}))

type Props = {
  className?: string
};

export function Logo({ className = "" }: Props): React.JSX.Element {
  const { classes } = useStyles()

  return (
    <Link to="/" className={`${classes.link} ${className}`}>
      <img className={classes.image} src={logo} alt="Логотип Body Coach"/>
    </Link>
  )
}
