import { lazy } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { AuthGuard } from "app/hocs/AuthGuard"
import { DashboardLayout } from "../layouts/dashboard"
import { RoleBasedGuard } from "../hocs/RoleBasedGuard"

// ----------------------------------------------------------------------

export const InstructorTablePage = lazy(() => import("pages").then(module => ({ default: module.InstructorTablePage })))
export const InstructorEditPage = lazy(() => import("pages").then(module => ({ default: module.InstructorEditPage })))
export const ArticleTablePage = lazy(() => import("pages").then(module => ({ default: module.ArticleTablePage })))
export const ArticleEditPage = lazy(() => import("pages").then(module => ({ default: module.ArticleEditPage })))
export const ScheduleTablePage = lazy(() => import("pages").then(module => ({ default: module.ScheduleTablePage })))
export const ScheduleEditPage = lazy(() => import("pages").then(module => ({ default: module.ScheduleEditPage })))
export const BannerTablePage = lazy(() => import("pages").then(module => ({ default: module.BannerTablePage })))
export const BannerEditPage = lazy(() => import("pages").then(module => ({ default: module.BannerEditPage })))
export const AboutPage = lazy(() => import("pages").then(module => ({ default: module.AboutPage })))

// export const CoursesTablePage = lazy(() => import("pages").then(module => ({ default: module.CoursesTablePage })))
// export const CoursesEditPage = lazy(() => import("pages").then(module => ({ default: module.CourseEditPage })))

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: "/dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Outlet/>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <Navigate to="/dashboard/blog" replace/>, index: true },
      {
        path: "instructors",
        children: [
          {
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <InstructorTablePage/>
              </RoleBasedGuard>
            ), index: true,
          },
          {
            path: "instructor",
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <InstructorEditPage/>
              </RoleBasedGuard>
            ),
          },
          {
            path: "instructor/:instructorId",
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <InstructorEditPage/>
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "blog",
        children: [
          {
            element: (
              <RoleBasedGuard roles={[ "admin", "moderator" ]}>
                <ArticleTablePage/>
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: "article",
            element: (
              <RoleBasedGuard roles={[ "admin", "moderator" ]}>
                <ArticleEditPage/>
              </RoleBasedGuard>
            ),
          },
          {
            path: "article/:articleId",
            element: (
              <RoleBasedGuard roles={[ "admin", "moderator" ]}>
                <ArticleEditPage/>
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "schedule",
        children: [
          {
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <ScheduleTablePage/>
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: "event",
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <ScheduleEditPage/>
              </RoleBasedGuard>
            ),
          },
          {
            path: "event/:scheduleEventId",
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <ScheduleEditPage/>
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "banners",
        children: [
          {
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <BannerTablePage/>
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: "banner",
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <BannerEditPage/>
              </RoleBasedGuard>
            ),
          },
          {
            path: "banner/:bannerId",
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <BannerEditPage/>
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "about",
        children: [
          {
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <AboutPage/>
              </RoleBasedGuard>
            ),
            index: true,
          },
        ],
      },
      // TODO: заглушка
      // {
      //   path: "training",
      //   children: [
      //     {
      //       path: "courses",
      //       children: [
      //         {
      //           element: (
      //             <RoleBasedGuard roles={[ "admin" ]}>
      //               <CoursesTablePage/>
      //             </RoleBasedGuard>
      //           ),
      //           index: true,
      //         },
      //         {
      //           path: "course",
      //           element: (
      //             <RoleBasedGuard roles={[ "admin" ]}>
      //               <CoursesEditPage/>
      //             </RoleBasedGuard>
      //           ),
      //         },
      //         {
      //           path: "course/:courseId",
      //           element: (
      //             <RoleBasedGuard roles={[ "admin" ]}>
      //               <CoursesEditPage/>
      //             </RoleBasedGuard>
      //           ),
      //         },
      //       ],
      //     },
          // {
          //   path: "marathons",
          //   children: [
          //     {
          //       element: (
          //         <RoleBasedGuard roles={[ "admin" ]}>
          //           <ArticleTablePage/>
          //         </RoleBasedGuard>
          //       ),
          //       index: true,
          //     },
          //     {
          //       path: "marathon",
          //       element: (
          //         <RoleBasedGuard roles={[ "admin" ]}>
          //           <ArticleEditPage/>
          //         </RoleBasedGuard>
          //       ),
          //     },
          //     {
          //       path: "marathon/:marathonId",
          //       element: (
          //         <RoleBasedGuard roles={[ "admin" ]}>
          //           <ArticleEditPage/>
          //         </RoleBasedGuard>
          //       ),
          //     },
          //   ],
          // },
          // {
          //   path: "webinars",
          //   children: [
          //     {
          //       element: (
          //         <RoleBasedGuard roles={[ "admin" ]}>
          //           <ArticleTablePage/>
          //         </RoleBasedGuard>
          //       ),
          //       index: true,
          //     },
          //     {
          //       path: "webinar",
          //       element: (
          //         <RoleBasedGuard roles={[ "admin" ]}>
          //           <ArticleEditPage/>
          //         </RoleBasedGuard>
          //       ),
          //     },
          //     {
          //       path: "webinar/:webinarId",
          //       element: (
          //         <RoleBasedGuard roles={[ "admin" ]}>
          //           <ArticleEditPage/>
          //         </RoleBasedGuard>
          //       ),
          //     },
          //   ],
          // },
          // {
          //   path: "professions",
          //   children: [
          //     {
          //       element: (
          //         <RoleBasedGuard roles={[ "admin" ]}>
          //           <ArticleTablePage/>
          //         </RoleBasedGuard>
          //       ),
          //       index: true,
          //     },
          //     {
          //       path: "profession",
          //       element: (
          //         <RoleBasedGuard roles={[ "admin" ]}>
          //           <ArticleEditPage/>
          //         </RoleBasedGuard>
          //       ),
          //     },
          //     {
          //       path: "profession/:professionId",
          //       element: (
          //         <RoleBasedGuard roles={[ "admin" ]}>
          //           <ArticleEditPage/>
          //         </RoleBasedGuard>
          //       ),
          //     },
          //   ],
          // },
          // {
          //   path: "conferences",
          //   children: [
          //     {
          //       element: (
          //         <RoleBasedGuard roles={[ "admin" ]}>
          //           <ArticleTablePage/>
          //         </RoleBasedGuard>
          //       ),
          //       index: true,
          //     },
          //     {
          //       path: "conference",
          //       element: (
          //         <RoleBasedGuard roles={[ "admin" ]}>
          //           <ArticleEditPage/>
          //         </RoleBasedGuard>
          //       ),
          //     },
          //     {
          //       path: "conference/:conferenceId",
          //       element: (
          //         <RoleBasedGuard roles={[ "admin" ]}>
          //           <ArticleEditPage/>
          //         </RoleBasedGuard>
          //       ),
          //     },
          //   ],
          // },
      //   ],
      // },
    ],
  },
]
