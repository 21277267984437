import { Link } from "react-router-dom"
import { createStyles, Title, Text, Button, Container, Group, rem } from "@mantine/core"
import { PATHS } from "shared/lib/paths"
import React from "react"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: rem(80),
    paddingBottom: rem(80),
  },

  label: {
    textAlign: "center",
    fontWeight: 900,
    fontSize: rem(220),
    lineHeight: 1,
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    color: theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2],

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(120),
    },
  },

  title: {
    textAlign: "center",
    fontWeight: 900,
    fontSize: rem(38),

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(32),
    },
  },

  description: {
    maxWidth: rem(500),
    margin: "auto",
    marginTop: theme.spacing.xl,
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
  },
}))

// ----------------------------------------------------------------------

type Props = {
  label: string
  title: string
  description: string
};

export function ErrorView({ label, title, description }: Props): React.JSX.Element {
  const { classes } = useStyles()

  return (
    <Container className={classes.root} m="auto">
      <div className={classes.label}>{label}</div>
      <Title className={classes.title}>{title}</Title>
      <Text color="dimmed" size="lg" align="center" className={classes.description}>
        {description}
      </Text>
      <Group position="center">
        <Button variant="subtle" size="md" component={Link} to={PATHS.ROOT}>
          На главную страницу
        </Button>
      </Group>
    </Container>
  )
}
