import { observer } from "mobx-react-lite"
import { Link, useLocation } from "react-router-dom"
import { createStyles, Navbar as MantineNavbar, Group, rem, NavLink } from "@mantine/core"
import {
  IconUsersGroup,
  IconArticle,
  IconFlag3,
  IconHome2,
  IconLogout,
  IconCalendar,
  IconBook2,
  IconBook,
  // IconSpeakerphone,
  // IconPresentationAnalytics,
  // IconStar,
  // IconFreeRights,
  IconStack3,
  type TablerIconsProps,
} from "@tabler/icons-react"
import { Logo } from "shared/ui/Logo"
import { DashboardNavbarStore, AuthStore } from "app/store"
import { PATHS } from "shared/lib/paths"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  content: {
    maxWidth: "100vw",
    maxHeight: "100vh",
  },

  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: `calc(${theme.spacing.md} * 1.5)`,
    borderBottom: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`,

    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    borderTop: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`,
  },

  link: {
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,
    marginBottom: 4,
  },
}))

// ----------------------------------------------------------------------

type Route = {
  linkTo: string
  label: string
  description?: string
  Icon: (props: TablerIconsProps) => JSX.Element
  roles: Array<string>
  children?: Array<Route>
}

const routes: Array<Route> = [
  { linkTo: PATHS.DASHBOARD.BLOG.ROOT, label: "Блог", Icon: IconArticle, roles: [ "admin", "moderator" ] },
  // TODO: заглушка
  // {
  //   linkTo: PATHS.DASHBOARD.TRAINING.ROOT,
  //   label: "Тренинги",
  //   Icon: IconBook,
  //   roles: [ "admin" ],
  //   children: [
  //     {
  //       linkTo: PATHS.DASHBOARD.TRAINING.COURSES.ROOT,
  //       label: "Учебные курсы",
  //       Icon: IconStack3,
  //       roles: [ "admin" ],
  //     },
  // {
  //   linkTo: PATHS.DASHBOARD.TRAINING.MARATHONS.ROOT,
  //   label: "Бесплатные марафоны",
  //   Icon: IconFreeRights,
  //   roles: [ "admin" ],
  // },
  // {
  //   linkTo: PATHS.DASHBOARD.TRAINING.WEBINARS.ROOT,
  //   label: "Вебинары",
  //   Icon: IconSpeakerphone,
  //   roles: [ "admin" ],
  // },
  // {
  //   linkTo: PATHS.DASHBOARD.TRAINING.PROFESSIONS.ROOT,
  //   label: "Профессии",
  //   Icon: IconStar,
  //   roles: [ "admin" ],
  // },
  // {
  //   linkTo: PATHS.DASHBOARD.TRAINING.CONFERENCES.ROOT,
  //   label: "Конференции",
  //   Icon: IconPresentationAnalytics,
  //   roles: [ "admin" ],
  // },
  //   ],
  // },
  { linkTo: PATHS.DASHBOARD.INSTRUCTORS.ROOT, label: "Преподаватели", Icon: IconUsersGroup, roles: [ "admin" ] },
  { linkTo: PATHS.DASHBOARD.SCHEDULE.ROOT, label: "Расписание", Icon: IconCalendar, roles: [ "admin" ] },
  {
    linkTo: PATHS.DASHBOARD.BANNERS.ROOT,
    label: "Баннеры",
    description: "Реклама на главной",
    Icon: IconFlag3,
    roles: [ "admin" ],
  },
  { linkTo: PATHS.DASHBOARD.ABOUT.ROOT, label: "Об организации", Icon: IconBook2, roles: [ "admin" ] },
]

// ----------------------------------------------------------------------

export const DashboardNavbar = observer(() => {
  const { classes } = useStyles()
  const { pathname } = useLocation()

  const { logout, user } = AuthStore
  const currentRole = user ? user.role : "unknown"

  const closeNavbar = () => {
    document.body.classList.remove("no-scroll")
    void DashboardNavbarStore.setOpened(false)
  }
  const handleLogout = () => {
    void closeNavbar()
    void logout()
  }

  const links = routes.map(({ children, label, Icon, description, linkTo, roles }) => {
    if (children !== undefined) {
      return (
        <NavLink
          key={linkTo}
          disabled={!roles.includes(currentRole)}
          className={classes.link}
          component={Link}
          label={label}
          icon={<Icon stroke={1.5}/>}
          description={description}
          to={linkTo}
          active={pathname.includes(linkTo)}
          childrenOffset={20}
        >
          {children.map((child) => (
            <NavLink
              key={child.linkTo}
              disabled={!child.roles.includes(currentRole)}
              className={classes.link}
              component={Link}
              label={child.label}
              icon={<child.Icon stroke={1.5}/>}
              to={child.linkTo}
              active={pathname.includes(child.linkTo)}
              onClick={closeNavbar}
            />
          ))}
        </NavLink>
      )
    }

    return (
      <NavLink
        disabled={!roles.includes(currentRole)}
        key={linkTo}
        className={classes.link}
        component={Link}
        label={label}
        icon={<Icon stroke={1.5}/>}
        description={description}
        to={linkTo}
        active={pathname.includes(linkTo)}
        onClick={closeNavbar}
      />
    )
  })

  return (
    <MantineNavbar
      className={classes.content}
      fixed
      hiddenBreakpoint="sm"
      hidden={!DashboardNavbarStore.isOpened}
      width={{ sm: 250, lg: 320 }}
      p="md"
    >
      <MantineNavbar.Section grow>
        <Group className={classes.header} position="apart">
          <Logo/>
        </Group>
        {links}
      </MantineNavbar.Section>

      <MantineNavbar.Section className={classes.footer}>
        <NavLink
          component={Link}
          className={classes.link}
          label="На главную"
          icon={<IconHome2 stroke={1.5}/>}
          to={PATHS.ROOT}
          onClick={closeNavbar}
        />
        <NavLink onClick={handleLogout} className={classes.link} label="Выход" icon={<IconLogout stroke={1.5}/>}/>
      </MantineNavbar.Section>
    </MantineNavbar>
  )
})
