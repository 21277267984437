import { makeAutoObservable, reaction, runInAction, observable } from "mobx"
import {
  create,
  getArticles,
  getArticleById,
  toggleArticlePublish,
  update,
  remove,
} from "shared/api/articles"
import {
  type ArticleCategory,
  type Article,
  type ArticleParams,
} from "shared/types/article"
import { umbrellaException } from "../../shared/lib/umbrellaException"

// ----------------------------------------------------------------------

class ArticleStoreImpl {
  categories = observable.array([
    "hormonalSystem",
    "nutriciology",
    "lfkAndRehabilitation",
    "trainingProcess",
    "groupDestinations",
    "psychologyAndSales",
  ] as Array<ArticleCategory>)

  search: string = ""
  page: number = 1
  perPage: number = 15
  publishedOnly: boolean = true

  articles: Array<Article> = []
  totalCount: number = 0

  error: string | null = null
  isLoading: boolean = false

  constructor() {
    makeAutoObservable(this)

    reaction(
      (): [ Array<ArticleCategory>, string, boolean ] => [
        this.categories.slice(),
        this.search,
        this.publishedOnly,
      ],
      ([ categories, search, publishedOnly ]) => {
        void this.initialLoad({
          categories: categories.join(","),
          search,
          publishedOnly,
        })
      },
    )
  }

  toggleCategory(category: ArticleCategory): void {
    if (this.categories.includes(category)) {
      this.categories.remove(category)
    } else {
      this.categories.push(category)
    }
  }

  toggleType = (checked: boolean): void => {
    this.publishedOnly = checked
  }

  setSearchValue(value: string): void {
    this.search = value
  }

  changePage(): void {
    if (this.totalCount > this.articles.length) {
      this.page += 1
    }
  }

  initialLoad = async (
    {
      categories,
      search,
      perPage,
      publishedOnly,
    }: Partial<ArticleParams> = {}): Promise<void> => {
    try {
      runInAction(() => {
        this.isLoading = true
        this.error = null
        this.page = 1
        this.publishedOnly = publishedOnly ?? this.publishedOnly
      })

      const { data } = await getArticles({
        page: 1,
        perPage: perPage ?? this.perPage,
        categories: categories ?? Array.from(this.categories).join(),
        search: search ?? this.search,
        publishedOnly: publishedOnly ?? this.publishedOnly,
      })

      runInAction(() => {
        this.articles = data.articles
        this.totalCount = data.totalCount
        this.isLoading = false

        this.changePage()
      })
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }

  loadMore = async (): Promise<void> => {
    try {
      this.isLoading = true
      this.error = null

      const { data } = await getArticles({
        page: this.page,
        perPage: this.perPage,
        categories: Array.from(this.categories).join(),
        search: this.search,
      })

      runInAction(() => {
        this.articles = [ ...this.articles, ...data.articles ]
        this.totalCount = data.totalCount
        this.isLoading = false

        this.changePage()
      })
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }

  togglePublish = async (articleId: string, published: boolean): Promise<void> => {
    try {
      runInAction(() => {
        this.isLoading = true
        this.error = null
      })

      const { data } = await toggleArticlePublish(articleId, published)

      runInAction(() => {
        const current = this.articles.find(({ _id }) => _id === data._id)

        if (current) {
          current.published = data.published
        }

        this.isLoading = false
      })
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }

  createArticle = async (body: FormData): Promise<boolean> => {
    try {
      this.isLoading = true
      this.error = null

      await create(body)

      runInAction(() => {
        this.isLoading = false
      })

      return true
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })

      return false
    }
  }

  updateArticle = async (id: string, body: FormData): Promise<boolean> => {
    try {
      this.isLoading = true
      this.error = null

      await update(id, body)

      runInAction(() => {
        this.isLoading = false
      })

      return true
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })

      return false
    }
  }

  loadArticle = async (articleId: string): Promise<Article | undefined> => {
    try {
      this.isLoading = true
      this.error = null

      const { data } = await getArticleById(articleId)

      runInAction(() => {
        this.isLoading = false
      })

      return data
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }

  removeArticle = async (articleId: string): Promise<void> => {
    try {
      this.isLoading = true
      this.error = null

      await remove(articleId)

      runInAction(() => {
        this.articles = this.articles.filter(
          (article) => article._id !== articleId,
        )
        this.totalCount -= 1
        this.isLoading = false
      })
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }
}

export const ArticleStore = new ArticleStoreImpl()
