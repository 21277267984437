import { makeAutoObservable, runInAction } from "mobx"
import {
  getAll,
  getById,
  getActive,
  remove,
  update,
  create,
  setActive,
} from "shared/api/banner"
import { type Banner } from "../../shared/types/banner"
import { umbrellaException } from "../../shared/lib/umbrellaException"

// ----------------------------------------------------------------------

class BannerStoreImpl {
  banners: Array<Banner> = []
  activeBanner: Banner | null = null
  totalCount: number = 0

  isLoading: boolean = false
  error: string | null = null

  constructor() {
    makeAutoObservable(this)
  }

  getBanners = async () => {
    try {
      runInAction(() => {
        this.isLoading = true
        this.error = null
      })

      const { data } = await getAll()

      runInAction(() => {
        this.banners = data.banners
        this.totalCount = data.totalCount
        this.isLoading = false
      })
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }

  setBannerActive = async (bannerId: string, state: boolean) => {
    try {
      runInAction(() => {
        this.isLoading = true
        this.error = null
      })

      const { data } = await setActive(bannerId, state)

      runInAction(() => {
        const oldActive = this.banners.find(banner => banner._id === data.oldActive?._id)

        if (oldActive) {
          oldActive.isActive = false
        }

        const newActive = this.banners.find(banner => banner._id === data.newActive._id)
        this.activeBanner = data.newActive

        if (newActive) {
          newActive.isActive = state
        }

        this.isLoading = false
      })
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }

  createBanner = async (body: FormData) => {
    try {
      this.isLoading = true
      this.error = null

      await create(body)

      runInAction(() => {
        this.isLoading = false
      })

      return true
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })

      return false
    }
  }

  updateBanner = async (bannerId: string, body: FormData) => {
    try {
      this.isLoading = true
      this.error = null

      const { data } = await update(bannerId, body)

      runInAction(() => {
        this.banners = this.banners.map((banner) => {
          return banner._id === data._id ? data : banner
        })

        this.isLoading = false
      })

      return true
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })

      return false
    }
  }

  getBanner = async (bannerId: string) => {
    try {
      this.isLoading = true
      this.error = null

      const { data } = await getById(bannerId)

      runInAction(() => {
        this.isLoading = false
      })

      return data
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }

  getActiveBanner = async () => {
    try {
      this.isLoading = true
      this.error = null

      const { data } = await getActive()

      runInAction(() => {
        this.activeBanner = data
        this.isLoading = false
      })
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }

  removeBanner = async (bannerId: string) => {
    try {
      this.isLoading = true
      this.error = null

      await remove(bannerId)

      runInAction(() => {
        this.banners = this.banners.filter(
          (banner) => banner._id !== bannerId,
        )
        this.totalCount -= 1
        this.isLoading = false
      })
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }
}

export const BannerStore = new BannerStoreImpl()
