import dayjs from "dayjs"

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null | undefined;

const inclinedMonths = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
]

export function getInclinedMonth(date: InputValue): string {
  return inclinedMonths[dayjs(date).month()]
}

export function fDate(date: InputValue, format?: string): string {
  const fm = format || "dddd, DD MMMM YYYY"

  return date ? dayjs(new Date(date)).format(fm) : ""
}
