import { api } from "./axios"
import { API_ENDPOINTS } from "./config"
import { type AxiosResponse } from "axios"
import { type  ErrorResponse } from "../types/common"
import {
  type AboutOrganization,
  type AboutOrganizationPageTag,
  type AboutOrganizationRequest,
} from "../types/aboutOrganization"

// ----------------------------------------------------------------------

export function getAboutOrganizationData(_pageTag: AboutOrganizationPageTag): Promise<AxiosResponse<AboutOrganization, ErrorResponse>> {
  return api.get(`${API_ENDPOINTS.pageContent}/${_pageTag}`)
}

export function updateAboutOrganizationData(_pageTag: AboutOrganizationPageTag, body: AboutOrganizationRequest): Promise<AxiosResponse<AboutOrganization, ErrorResponse>> {
  return api.patch(`${API_ENDPOINTS.pageContent}/${_pageTag}`, body)
}
