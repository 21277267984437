import { observer } from "mobx-react-lite"
import { Link, useLocation } from "react-router-dom"
import { Drawer, Burger, createStyles, rem, NavLink, Button } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { ThemeButton } from "features/themeButton"
import { mainNavigateRoutes } from "shared/lib/constants"
import { PATHS } from "shared/lib/paths"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  burger: {
    [theme.fn.largerThan("xl")]: {
      display: "none",
    },
  },

  content: {
    borderTopLeftRadius: theme.radius.xl,
    borderBottomLeftRadius: theme.radius.xl,
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    position: "relative",

    [theme.fn.smallerThan("xs")]: {
      borderRadius: 0,
    },
  },

  close: {
    position: "absolute",
    right: 26,
    top: 26,
    zIndex: 1001,
  },

  header: {
    padding: theme.spacing.xl,
    marginBottom: theme.spacing.xl,
  },

  title: {
    fontSize: rem(32),
    fontWeight: 700,
  },

  body: {
    padding: theme.spacing.xl,
    paddingTop: 0,
  },

  link: {
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    marginBottom: theme.spacing.xs,
    borderRadius: theme.radius.md,
    fontWeight: 500,
  },
}))

// ----------------------------------------------------------------------

export const MobileNav = observer(function MobileNav() {
  const { classes, theme } = useStyles()
  const { pathname } = useLocation()

  const [ opened, { toggle, close } ] = useDisclosure(false)

  return (
    <>
      <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm"/>

      <Drawer.Root position="right" opened={opened} onClose={close}>
        <Drawer.Overlay/>
        <Drawer.Content className={classes.content}>
          <Drawer.CloseButton color="dark" variant="light" radius="xl" size="xl" className={classes.close}/>
          <Drawer.Header className={classes.header}>
            <Drawer.Title className={classes.title}>Меню</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body className={classes.body}>
            {mainNavigateRoutes.map(({ label, Icon, linkTo, isExternal }) => (
              <NavLink
                styles={{ label: { fontSize: theme.fontSizes.md } }}
                key={linkTo}
                className={classes.link}
                component={Link}
                label={label}
                icon={<Icon stroke={1.5}/>}
                to={linkTo}
                active={linkTo === pathname}
                onClick={close}
                target={isExternal ? "_blank" : undefined}
                rel={isExternal ? "noopener noreferrer" : undefined}
              />
            ))}
            <Button
              component={Link}
              to={PATHS.EXTERNAL.ACADEMY.ROOT}
              target="_blank"
              rel="noopener noreferrer"
              size="md"
              radius="md"
              variant="gradient"
              mt="xl"
              fullWidth
            >
              Личный кабинет
            </Button>

            <ThemeButton/>
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>
    </>
  )
})
