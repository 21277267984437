import { type ArticleParams, type Article } from "shared/types/article"
import { api } from "./axios"
import { API_ENDPOINTS } from "./config"
import { type AxiosResponse } from "axios"
import { type  ErrorResponse } from "../types/common"

// ----------------------------------------------------------------------

export function getArticles(params?: Partial<ArticleParams>): Promise<AxiosResponse<{
  totalCount: number
  articles: Array<Article>
}, ErrorResponse>> {
  return api.get(API_ENDPOINTS.articles, { params })
}

export function getArticleById(articleId: string): Promise<AxiosResponse<Article, ErrorResponse>> {
  return api.get(`${API_ENDPOINTS.articles}/${articleId}`)
}

export function update(articleId: string, body: FormData): Promise<AxiosResponse<Article, ErrorResponse>> {
  return api.patch(`${API_ENDPOINTS.articles}/${articleId}`, body)
}

export function toggleArticlePublish(articleId: string, published: boolean): Promise<AxiosResponse<Article, ErrorResponse>> {
  return api.patch(`${API_ENDPOINTS.articles}/${articleId}`, { published })
}

export function create(body: FormData): Promise<AxiosResponse<Article, ErrorResponse>> {
  return api.post(API_ENDPOINTS.articles, body)
}

export function remove(articleId: string): Promise<AxiosResponse<unknown>> {
  return api.delete(`${API_ENDPOINTS.articles}/${articleId}`)
}
