import { Title, Accordion, createStyles, rem } from "@mantine/core"
import React from "react"

const useStyles = createStyles((theme) => ({
  title: {
    color: theme.white,
    fontWeight: 700,
    fontSize: "3rem",
    marginBottom: "3rem",

    [theme.fn.smallerThan("md")]: {
      fontSize: "2.5rem",
      marginBottom: "2.5rem",
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: "2rem",
      marginBottom: "1.5rem",
    },
  },

  item: {
    marginBottom: theme.spacing.lg,
    borderBottom: `${rem(1)} solid ${theme.colors.dark[5]}`,
  },

  сontrol: {
    color: theme.white,
    fontSize: theme.fontSizes.xl,

    "&:hover": {
      backgroundColor: theme.black,
    },
  },

  panel: {
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
    color: theme.colors.gray[5],
  },
}))

const data = [
  {
    title: "Отличается ли по качеству дистанционное обучение от живого?",
    text: "С учётом многолетнего опыта работы, наш образовательный центр максимально адаптировал практические блоки обучения таким образом, что разбор техники выполнения упражнений ничем не уступает живому формату обучения. Обозначили все векторы направления движений с дополнительными акцентами важнейших нюансов. Все видеоматериалы доступны неограниченное количество времени, вы всегда можете пересмотреть уроки в любое удобное для вас время.",
  },
  {
    title: "Подойдет ли учебная программа новичку?",
    text: "Образовательный курс устроен таким образом, что подойдет как специалисту, который уже имеет определенный уровень знаний в сфере фитнеса и работает тренером, так и тем, кто только хочет освоить для себя новую профессию.\n\nС каждым студентом работает личный куратор, который помогает усвоить учебный материал и отвечает на все возникающие вопросы в процессе обучения.",
  },
  {
    title: "Есть ли рассрочка? Какие условия?",
    text: "В нашем образовательном центре есть возможность приобрести обучение в рассрочку. Без % и переплат. Вы выбираете комфортную для вас сумму платежа и приступаете к обучению.",
  },
  {
    title: "Можно ли вернуть деньги, если мне не подошло обучение?",
    text: "Да, вы можете вернуть денежные средства в соответствии с условиями заключенного договора на оказание образовательных услуг или договора-оферты.",
  },
]

export function FooterFaq(): React.JSX.Element {
  const { classes } = useStyles()

  return (
    <div>
      <Title className={classes.title}>Часто задаваемые вопросы</Title>

      <Accordion>
        {data.map(({ title, text }) => (
          <Accordion.Item key={title} className={classes.item} value={title}>
            <Accordion.Control className={classes.сontrol}>{title}</Accordion.Control>
            <Accordion.Panel className={classes.panel}>{text}</Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  )
}
