import { useMantineColorScheme, ActionIcon, useMantineTheme, Button, type ColorScheme } from "@mantine/core"
import { useLocalStorage } from "@mantine/hooks"
import { IconSun, IconMoonStars } from "@tabler/icons-react"
import { observer } from "mobx-react-lite"

// ----------------------------------------------------------------------

type Props = {
  variant?: "icon" | "default"
};

export const ThemeButton = observer(function ThemeButton({ variant = "default" }: Props) {
    const [ , setColorScheme ] = useLocalStorage<ColorScheme>({ key: "color-scheme", defaultValue: "light" })
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { toggleColorScheme } = useMantineColorScheme()
    const theme = useMantineTheme()

    const toggleTheme = () => {
      toggleColorScheme()
      setColorScheme(theme.colorScheme === "light" ? "dark" : "light")
    }

    return variant === "default" ? (
      <Button
        leftIcon={theme.colorScheme === "dark" ? <IconSun size="1.2rem"/> : <IconMoonStars size="1.2rem"/>}
        size="md"
        radius="md"
        mt="sm"
        color="dark"
        variant="subtle"
        fullWidth
        onClick={() => toggleColorScheme()}
      >
        {theme.colorScheme === "dark" ? "Светлая" : "Тёмная"} тема
      </Button>
    ) : (
      <ActionIcon
        onClick={toggleTheme}
        size="xl"
        radius="xl"
        sx={{
          backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
          color: theme.colorScheme === "dark" ? theme.colors.yellow[4] : theme.colors.blue[6],
        }}
      >
        {theme.colorScheme === "dark" ? <IconSun size="1.2rem"/> : <IconMoonStars size="1.2rem"/>}
      </ActionIcon>
    )
  },
)
