import { lazy } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { GuestGuard } from "app/hocs/GuestGuard"
import { CompactLayout } from "../layouts/compact"

// ----------------------------------------------------------------------

export const LoginPage = lazy(() => import("pages").then(module => ({ default: module.LoginPage })))

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: "/auth",
    element: (
      <GuestGuard>
        <CompactLayout>
          <Outlet/>
        </CompactLayout>
      </GuestGuard>
    ),
    children: [
      {
        element: <Navigate to="/auth/login" replace/>,
        index: true,
      },
      {
        path: "login",
        element: <LoginPage/>,
      },
    ],
  },
]
