import { makeAutoObservable, reaction, runInAction } from "mobx"
import {
  type ScheduleEvent,
  type ScheduleEventRequest,
} from "../../shared/types/scheduleEvent"
import { create, getAll, remove } from "../../shared/api/draftScheduleEvent"
import { umbrellaException } from "../../shared/lib/umbrellaException"

// ----------------------------------------------------------------------

class DraftScheduleEventStoreImpl {
  search: string = ""
  page: number = 1
  perPage: number = 15

  drafts: Array<ScheduleEvent> = []
  totalCount: number = 0

  error: string | null = null
  isLoading: boolean = false

  constructor() {
    makeAutoObservable(this)

    reaction(
      () => this.search,
      (search) => void this.getDrafts(search),
    )
  }

  setSearchValue(value: string) {
    this.search = value
  }

  changePage() {
    if (this.totalCount > this.drafts.length) {
      this.page += 1
    }
  }

  getDrafts = async (search?: string) => {
    try {
      this.isLoading = true
      this.error = null
      this.page = 1

      const { data } = await getAll({
        page: 1,
        perPage: this.perPage,
        search: search ?? this.search,
      })

      runInAction(() => {
        this.drafts = data.drafts
        this.totalCount = data.totalCount
        this.isLoading = false

        this.changePage()
      })
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }

  loadMoreDrafts = async () => {
    try {
      this.isLoading = true
      this.error = null

      const { data } = await getAll({
        page: this.page,
        perPage: this.perPage,
        search: this.search,
      })

      runInAction(() => {
        this.drafts = this.drafts.concat(data.drafts)
        this.totalCount = data.totalCount
        this.isLoading = false

        this.changePage()
      })
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }

  saveDraft = async (body: ScheduleEventRequest) => {
    try {
      this.isLoading = true
      this.error = null

      await create(body)

      runInAction(() => {
        this.isLoading = false
      })

      return true
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })

      return false
    }
  }

  removeDraft = async (draftId: string) => {
    try {
      this.isLoading = true
      this.error = null

      await remove(draftId)

      runInAction(() => {
        this.drafts = this.drafts.filter(
          (draft) => draft._id !== draftId,
        )
        this.totalCount -= 1
        this.isLoading = false
      })
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }
}

export const DraftScheduleEventStore = new DraftScheduleEventStoreImpl()
