import React, { type PropsWithChildren } from "react"
import { observer } from "mobx-react-lite"
import { Box, createStyles, Divider, rem, Stack, Text } from "@mantine/core"

type LandingSectionContainerProps = PropsWithChildren<{
  label: string
}>

const useStyles = createStyles((theme) => ({
  box: {
    padding: theme.spacing.xs,
    border: `1px dashed ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.md,
  },

  highlight: {
    position: "relative",
    color: theme.white,
    background: theme.fn.variant({ variant: "gradient", gradient: { from: "indigo", to: "cyan" } }).background,
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
    lineHeight: "36px",
  },
}))

export const LandingSectionContainer = observer(function LandingSectionContainer(props: LandingSectionContainerProps) {
  const { label, children } = props
  const { classes } = useStyles()

  return (
    <Box>
      <Divider
        variant="solid"
        label={
          <Text mb={8} fz={18} fw={500}>Секция&ensp;
            <Text span className={classes.highlight}>
              {label}
            </Text>
          </Text>
        }
      />
      <Stack spacing="xl" className={classes.box}>
        {children}
      </Stack>
    </Box>
  )
})
