import { api } from "./axios"
import { API_ENDPOINTS } from "./config"
import { type AxiosResponse } from "axios"
import { type MailRequest } from "../types/mail"
import { type ErrorResponse } from "../types/common"

// ----------------------------------------------------------------------

export function sendParticipationRequest(body: MailRequest): Promise<AxiosResponse<{
  message: string
}, ErrorResponse>> {
  return api.post(`${API_ENDPOINTS.mail}/participation`, body)
}
