import { Flex, Loader, createStyles, type MantineColor } from "@mantine/core"
import React from "react"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
}))

// ----------------------------------------------------------------------

type Props = {
  color?: MantineColor
}

export function LoadingScreen(props: Props): React.JSX.Element {
  const { color = "blue" } = props
  const { classes } = useStyles()

  return (
    <Flex justify="center" align="center" className={classes.loader}>
      <Loader size="xl" color={color}/>
    </Flex>
  )
}
