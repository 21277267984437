import {
  type ScheduleEvent, type ScheduleEventRequest,
  type DraftScheduleEventParams,
} from "shared/types/scheduleEvent"
import { api } from "./axios"
import { API_ENDPOINTS } from "./config"
import { type AxiosResponse } from "axios"
import { type ErrorResponse } from "../types/common"

// ----------------------------------------------------------------------

export function getAll(params?: Partial<DraftScheduleEventParams>): Promise<AxiosResponse<{
  totalCount: number
  drafts: Array<ScheduleEvent>
}, ErrorResponse>> {
  return api.get(API_ENDPOINTS.scheduleEventDrafts, { params })
}

export function create(body: ScheduleEventRequest): Promise<AxiosResponse<ScheduleEvent, ErrorResponse>> {
  return api.post(API_ENDPOINTS.scheduleEventDrafts, body)
}

export function remove(draftId: string): Promise<AxiosResponse<unknown, ErrorResponse>> {
  return api.delete(`${API_ENDPOINTS.scheduleEventDrafts}/${draftId}`)
}
