import { createStyles, Text, Input, Button, Grid, Anchor, Alert, TextInput } from "@mantine/core"
import { APP_URL } from "../../shared/config"
import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { IconAlertCircle, IconCircleCheck } from "@tabler/icons-react"
import { IMaskInput } from "react-imask"
import { useForm, yupResolver } from "@mantine/form"
import { sendParticipationRequest } from "../../shared/api/mail"
import * as Yup from "yup"
import { type  AxiosError } from "axios"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  form: {
    width: "50%",

    [theme.fn.smallerThan("sm")]: {
      width: "100%",
      marginTop: theme.spacing.xl,
    },
  },

  alert: {
    width: "50%",
    padding: theme.spacing.xl,

    [theme.fn.smallerThan("sm")]: {
      width: "100%",
      padding: theme.spacing.sm,
      marginTop: theme.spacing.xl,
    },
  },
}))

const schema = Yup.object().shape({
  name: Yup.string().required("Имя обязательно"),
  email: Yup.string().email("Неправильный формат почты").required("Электронный адрес обязателен"),
  phone: Yup.string().required("Телефон обязателен").length(18, "Пожалуйста, укажите телефон полностью"),
})

// ----------------------------------------------------------------------


export const SubscribeForm = observer(function SubscribeForm() {
    const { classes } = useStyles()

    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const [ isSuccess, setIsSuccess ] = useState<boolean>(false)
    const [ error, setError ] = useState<string | null>(null)

    const form = useForm({
      validateInputOnChange: true,
      validate: yupResolver(schema),
      initialValues: {
        name: "",
        phone: "",
        email: "",
      },
    })

    const onSubmit = (values: typeof form.values): void => {
      setIsLoading(true)
      setIsSuccess(false)
      setError(null)

      const body = { ...values, target: "Футер, форма подписки 'Поможем в выборе!'" }

      void sendParticipationRequest(body)
        .then(() => {
          setIsSuccess(true)
        })
        .catch((err: AxiosError<{ message: string }>) => {
          if (err.response) {
            setError(err.response.data.message)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    useEffect(() => {
      return () => {
        form.reset()
        setIsLoading(false)
        setIsSuccess(false)
        setError(null)
      }
      // eslint-disable-next-line
    }, [])

    return isSuccess ? (
      <Alert
        className={classes.alert}
        styles={{ title: { fontSize: 18 }, message: { fontSize: 16 } }}
        icon={<IconCircleCheck/>}
        title="Заявка успешно отправлена!"
        color="green"
        radius="xl"
      >
        В ближайшее время с Вами свяжется наш сотрудник и ответит на все Вами вопросы.
      </Alert>
    ) : (
      <form className={classes.form} onSubmit={form.onSubmit(onSubmit)}>
        <Grid m={0}>
          <Grid.Col span={12}>
            <TextInput
              placeholder="Имя"
              radius={12}
              size="md"
              {...form.getInputProps("name")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <Input.Wrapper id="participant-form-phone" error={form.errors["phone"]} size="md">
              <Input
                component={IMaskInput}
                mask="+7 (000) 000-00-00"
                id="participant-form-phone"
                placeholder="Телефон"
                radius={12}
                size="md"
                {...form.getInputProps("phone")}
              />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextInput {...form.getInputProps("email")} placeholder="Электронная почта" radius={12} size="md"/>
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <Button
              loading={isLoading}
              disabled={!form.isDirty() || !form.isValid()}
              type="submit"
              fullWidth
              variant="gradient"
              size="md"
              radius={12}
            >
              Отправить
            </Button>
          </Grid.Col>
          <Grid.Col span={12}>
            <Text fz="sm" c="dimmed">
              Нажимая на кнопку &#34;Отправить&#34;, я соглашаюсь с&nbsp;
              <Anchor component="a" href={`${APP_URL}/privacy-policy`} target="_blank">
                политикой конфиденциальности
              </Anchor>
            </Text>
          </Grid.Col>

          {error && (<Grid.Col span={12}>
              <Alert
                mt="xl"
                icon={<IconAlertCircle/>}
                title="Ошибка отправки данных"
                color="red"
              >
                {error}
              </Alert>
            </Grid.Col>
          )}

        </Grid>
      </form>
    )
  },
)
