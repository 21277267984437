import { type LoginBody } from "shared/types/auth"
import { type User } from "shared/types/user"
import { api } from "./axios"
import { API_ENDPOINTS } from "./config"
import { type AxiosResponse } from "axios"
import { type ErrorResponse } from "../types/common"

// ----------------------------------------------------------------------

export function login(body: LoginBody): Promise<AxiosResponse<{
  user: User
  accessToken: string
}, ErrorResponse>> {
  return api.post(API_ENDPOINTS.auth.login, body)
}

export function logout(): Promise<AxiosResponse<never, ErrorResponse>> {
  return api.delete(API_ENDPOINTS.auth.logout)
}

export function refresh(): Promise<AxiosResponse<{ user: User, accessToken: string }, ErrorResponse>> {
  return api.get(API_ENDPOINTS.auth.refresh)
}
