import { lazy } from "react"
import { Outlet } from "react-router-dom"
import { MainLayout } from "../layouts/main"

// ----------------------------------------------------------------------

export const InstructorListPage = lazy(() => import("pages").then(module => ({ default: module.InstructorListPage })))
export const ScheduleListPage = lazy(() => import("pages").then(module => ({ default: module.ScheduleListPage })))
export const ArticleListPage = lazy(() => import("pages").then(module => ({ default: module.ArticleListPage })))
export const ArticleDetailsPage = lazy(() => import("pages").then(module => ({ default: module.ArticleDetailsPage })))
export const AffiliateProgramPage = lazy(() => import("pages").then(module => ({ default: module.AffiliateProgramPage })))
export const OfertaPage = lazy(() => import("pages").then(module => ({ default: module.OfertaPage })))
export const UseCookiesPage = lazy(() => import("pages").then(module => ({ default: module.UseCookiesPage })))
export const PrivacyPolicyPage = lazy(() => import("pages").then(module => ({ default: module.PrivacyPolicyPage })))
export const AboutOrganizationPage = lazy(() => import("pages").then(module => ({ default: module.AboutOrganizationPage })))

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Outlet/>
      </MainLayout>
    ),
    children: [
      { path: "instructors", element: <InstructorListPage/> },
      {
        path: "blog",
        children: [
          { element: <ArticleListPage/>, index: true },
          { path: ":articleId", element: <ArticleDetailsPage/> },
        ],
      },
      { path: "schedule", element: <ScheduleListPage/> },
      { path: "affiliate-program", element: <AffiliateProgramPage/> },
      { path: "oferta", element: <OfertaPage/> },
      { path: "privacy-policy", element: <PrivacyPolicyPage/> },
      { path: "cookies", element: <UseCookiesPage/> },
      { path: "about-organization", element: <AboutOrganizationPage/> },
    ],
  },
]
