import { Link } from "react-router-dom"
import { createStyles, Title, Text, Button, Group, rem, Box } from "@mantine/core"
import { PATHS } from "shared/lib/paths"
import React from "react"
import { observer } from "mobx-react-lite"
import { Header } from "../../../../widgets/header"

const useStyles = createStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
    flex: 1,
  },

  container: {
    marginTop: "15%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  shadowText: {
    textAlign: "center",
    fontWeight: 900,
    fontSize: rem(220),
    lineHeight: 1,
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    color: theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2],

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(120),
    },
  },

  title: {
    textAlign: "center",
    fontWeight: 900,
    fontSize: rem(38),

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(32),
    },
  },

  description: {
    padding: "0 24px",
    maxWidth: rem(768),
    textAlign: "center",
    marginTop: theme.spacing.xs,
    marginBottom: `calc(${theme.spacing.md} * 1.5)`,
  },

  text: {
    whiteSpace: "pre-line",
    textAlign: "center",
    marginBottom: `calc(${theme.spacing.md} * 1.5)`,
  },
}))

type Props = {
  shadowText: string
  title: string
  description: string
  text?: string
};

export const ErrorPage = observer(function (props: Props) {
    const {
      shadowText,
      title,
      description,
      text,
    } = props
    const { classes } = useStyles()

    return (
      <div className={classes.root}>
        <Box w="100%">
          <Header simple/>
        </Box>

        <div className={classes.container}>
          <div className={classes.shadowText}>{shadowText}</div>
          <Title className={classes.title}>{title}</Title>
          <Text color="dimmed" size="lg" align="center" className={classes.description}>
            {description}
          </Text>
          {text && (<div className={classes.text}>{text}</div>)}
          <Group position="center">
            <Button size="lg" variant="light" radius="lg" component={Link} to={PATHS.ROOT}>
              На главную страницу
            </Button>
          </Group>
        </div>
      </div>
    )
  },
)
