import React from "react"
import { observer } from "mobx-react-lite"
import { Link } from "react-scroll"
import { useMediaQuery } from "@mantine/hooks"
import { IconRocket, IconCalendar } from "@tabler/icons-react"
import { createStyles, rem, List, ThemeIcon, Container, Group, Text, Button, type MantineColor } from "@mantine/core"
import { Image } from "shared/ui/Image"
import { type LandingDto } from "../../../model"
import { API_URL } from "../../../../shared/config"
import { fDate } from "../../../../shared/lib/dates"

const useStyles = createStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: "100vh",
    background: "#f1f1f1",
    position: "relative",
    zIndex: 0,

    "&::before": {
      content: "''",
      width: "100%",
      height: "100%",
      backgroundImage: "url(/assets/abstract_lines.png)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "absolute",
      bottom: 0,
      left: 0,
      zIndex: -1,
      opacity: 0.1,
    },

    "&::after": {
      content: "''",
      width: "100%",
      height: "100%",
      backgroundImage: "url(/assets/abstract_lines.png)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "absolute",
      bottom: 0,
      left: 0,
      zIndex: -1,
      opacity: 0.1,
      transform: "rotate(-180deg)",
    },
  },

  container: {
    maxWidth: 1320,
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: 1,
  },

  inner: {
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    paddingTop: 90,
    paddingBottom: 90,

    [theme.fn.smallerThan("md")]: {
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column-reverse",
      gap: 24,
      paddingTop: 90,
      paddingBottom: 48,
    },
  },

  content: {
    maxWidth: "45%",

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
    },
  },

  title: {
    whiteSpace: "pre-line",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontSize: rem(54),
    lineHeight: 1.5,
    fontWeight: 900,

    [theme.fn.smallerThan("md")]: {
      textAlign: "center",
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(24),
      lineHeight: 1.7,
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },

  subTitle: {
    marginTop: 24,

    [theme.fn.smallerThan("md")]: {
      textAlign: "center",
      fontSize: 20,
    },

    [theme.fn.smallerThan("xs")]: {
      marginTop: 12,
      fontSize: 18,
    },
  },

  image: {
    maxWidth: 600,
  },

  list: {
    marginTop: 32,
    paddingLeft: theme.spacing.xl,

    [theme.fn.smallerThan("md")]: {
      paddingLeft: 0,
      justifyContent: "center",
      marginTop: 24,
    },
  },

  btnGroup: {
    marginTop: 48,

    [theme.fn.smallerThan("md")]: {
      justifyContent: "center",
      marginTop: 24,
    },
  },
}))

type Props = {
  landing: LandingDto
  landingColors: {
    primary: MantineColor
    secondary: MantineColor
  }
}

export const LandingSectionHero = observer(function LandingSectionHero(props: Props) {
  const { title, subtitle, image } = props.landing.sections.hero
  const { startDate, duration } = props.landing
  const landingColors = props.landingColors

  const { classes } = useStyles()
  const md = useMediaQuery("(max-width: 768px)")
  const xs = useMediaQuery("(max-width: 576px)")

  const offset = md ? -48 : xs ? -36 : -72

  return (
    <section id="main" className={classes.wrapper}>
      <Container px="md" className={classes.container}>
        <div className={classes.inner}>
          <div className={classes.content}>
            <div className={classes.title}>
              {title}
            </div>
            <Text className={classes.subTitle} size={24} color="dimmed">
              {subtitle}
            </Text>

            <Group className={classes.list}>
              <List size={xs ? "sm" : "md"} center spacing={24}>
                <List.Item
                  icon={
                    <ThemeIcon
                      size={xs ? 28 : 36}
                      radius="lg"
                      variant="gradient"
                      gradient={{ from: `${landingColors.secondary}.7`, to: `${landingColors.primary}.7`, deg: 105 }}
                    >
                      <IconCalendar size={xs ? 20 : 24}/>
                    </ThemeIcon>
                  }
                >
                  <Text size={xs ? "md" : "xl"} fw={700}>
                    Старт обучения&nbsp;&nbsp;–&nbsp;&nbsp;
                    {fDate(startDate, "D MMMM")}
                  </Text>
                </List.Item>
                <List.Item
                  icon={
                    <ThemeIcon
                      size={xs ? 28 : 36}
                      radius="lg"
                      variant="gradient"
                      gradient={{ from: `${landingColors.secondary}.7`, to: `${landingColors.primary}.7`, deg: 105 }}
                    >
                      <IconRocket size={xs ? 20 : 24}/>
                    </ThemeIcon>
                  }
                >
                  <Text size={xs ? "md" : "xl"} fw={700}>
                    Продолжительность&nbsp;&nbsp;–&nbsp;&nbsp;
                    {duration}
                  </Text>
                </List.Item>
              </List>
            </Group>

            <Group className={classes.btnGroup}>
              <Button
                radius="lg"
                size={md ? "md" : "lg"}
                className={classes.control}
                variant="gradient"
                gradient={{ from: `${landingColors.secondary}.7`, to: `${landingColors.primary}.7`, deg: 105 }}
                to={"prices"}
                smooth={true}
                duration={1000}
                component={Link}
                offset={offset}
              >
                Начать обучение
              </Button>
            </Group>
          </div>
          <Image
            objectFit="contain"
            sx={{ borderRadius: 16 }}
            src={`${API_URL}/public${image}`}
            className={classes.image}
          />
        </div>
      </Container>
    </section>
  )
})
