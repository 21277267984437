import { makeAutoObservable, reaction, runInAction } from "mobx"
import {
  getScheduleEvents, getScheduleEventById, create,
  remove,
  update,
} from "shared/api/scheduleEvents"
import {
  type ScheduleEvent,
  type ScheduleEventParams, type ScheduleEventRequest,
} from "shared/types/scheduleEvent"
import { umbrellaException } from "../../shared/lib/umbrellaException"

// ----------------------------------------------------------------------

class ScheduleEventStoreImpl {
  scheduleEvents: Array<ScheduleEvent> = []
  totalCount: number = 0

  professionOnly: boolean = false
  search: string = ""

  error: string | null = null
  isLoading: boolean = false

  constructor() {
    makeAutoObservable(this)

    reaction(
      (): [ string, boolean ] => [ this.search, this.professionOnly ],
      ([ search, professionOnly ]) => {
        void this.load({ search, professionOnly })
      },
    )
  }

  setSearchValue(value: string) {
    this.search = value
  }

  toggleType = (checked: boolean) => {
    this.professionOnly = checked
  }

  load = async (
    {
      search,
      professionOnly,
    }: Partial<ScheduleEventParams> = {}) => {
    try {
      this.isLoading = true
      this.error = null

      const { data } = await getScheduleEvents({
        professionOnly: professionOnly ?? this.professionOnly,
        search: search ?? this.search,
      })

      runInAction(() => {
        this.scheduleEvents = data.scheduleEvents
        this.totalCount = data.totalCount
        this.isLoading = false
      })
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }

  createScheduleEvent = async (body: ScheduleEventRequest) => {
    try {
      this.isLoading = true
      this.error = null

      await create(body)

      runInAction(() => {
        this.isLoading = false
      })

      return true
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })

      return false
    }
  }

  updateScheduleEvent = async (id: string, body: ScheduleEventRequest) => {
    try {
      this.isLoading = true
      this.error = null

      const { data } = await update(id, body)

      runInAction(() => {
        this.scheduleEvents = this.scheduleEvents.map((scheduleEvent) => {
          return scheduleEvent._id === data._id ? data : scheduleEvent
        })

        this.isLoading = false
      })

      return true
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })

      return false
    }
  }

  loadScheduleEvent = async (scheduleEventId: string) => {
    try {
      this.isLoading = true
      this.error = null

      const { data } = await getScheduleEventById(scheduleEventId)

      runInAction(() => {
        this.isLoading = false
      })

      return data
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }

  removeScheduleEvent = async (scheduleEventId: string) => {
    try {
      this.isLoading = true
      this.error = null

      await remove(scheduleEventId)

      runInAction(() => {
        this.scheduleEvents = this.scheduleEvents.filter(
          (scheduleEvent) => scheduleEvent._id !== scheduleEventId,
        )
        this.totalCount -= 1
        this.isLoading = false
      })
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }
}

export const ScheduleEventStore = new ScheduleEventStoreImpl()
