import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"

dayjs.extend(duration)

type DateString = string

export function getEventStatus(dateString: DateString): string {
  const eventDate = dayjs(dateString)
  const now = dayjs()

  if (eventDate.isBefore(now)) {
    return "Мероприятие уже началось"
  }

  const diff = dayjs.duration(eventDate.diff(now))
  const years = diff.years()
  const months = diff.months()
  const days = diff.days()

  const parts: Array<string> = []
  if (years > 0) parts.push(`${years} ${pluralize(years, "год", "года", "лет")}`)
  if (months > 0) parts.push(`${months} ${pluralize(months, "месяц", "месяца", "месяцев")}`)
  if (days > 0) parts.push(`${days} ${pluralize(days, "день", "дня", "дней")}`)

  return `Осталось ${parts.join(" ")}`
}

function pluralize(n: number, one: string, few: string, many: string): string {
  return n % 10 === 1 && n % 100 !== 11 ? one : (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? few : many)
}
