export const config = {
  REACT_APP_API_URL: "http://localhost:5000",
  REACT_APP_URL: "http://localhost:3000",
}

// ----------------------------------------------------------------------

const isProd = process.env.NODE_ENV === "production"

type ConfigKey = keyof typeof config;
type ConfigValue<T extends ConfigKey> = (typeof config)[T];

const getEnvVar = <T extends ConfigKey>(key: T): ConfigValue<T> => {
  const value = process.env[key]
  return value !== undefined ? (value as ConfigValue<T>) : config[key]
}

const getConfigValue = <T extends ConfigKey>(key: T): ConfigValue<T> => {
  return isProd ? getEnvVar(key) : config[key]
}

// ----------------------------------------------------------------------

export const API_URL = getConfigValue("REACT_APP_API_URL")
export const APP_URL = getConfigValue("REACT_APP_URL")
