import { createStyles, Container } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { DashboardNavbar } from "./DashboardNavbar"
import { DashboardHeader } from "./DashboardHeader"
import React from "react"
import { observer } from "mobx-react-lite"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    boxSizing: "border-box",
    minWidth: 320,
    minHeight: "100vh",
  },

  main: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    boxSizing: "border-box",
    width: "100vw",
    paddingLeft: "calc(var(--mantine-navbar-width, 0px))",
  },

  body: {
    flex: 1,
    display: "flex",
    width: "100%",
  },
}))

// ----------------------------------------------------------------------

export type Props = {
  children: React.ReactNode
};

export const DashboardLayout = observer(function DashboardLayout({ children }: Props) {
  const { classes, theme } = useStyles()
  const matches = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  return (
    <section className={classes.wrapper}>
      <DashboardNavbar/>
      <main className={classes.main}>
        {matches && <DashboardHeader/>}
        <Container size="xl" py="md" className={classes.body}>
          {children}
        </Container>
      </main>
    </section>
  )
})
