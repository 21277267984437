import {
  Group,
  Text,
  useMantineTheme,
  BackgroundImage,
  ThemeIcon,
  Overlay,
  createStyles,
} from "@mantine/core"
import { IconUpload, IconPhoto, IconX } from "@tabler/icons-react"
import { Dropzone, type DropzoneProps, IMAGE_MIME_TYPE, type FileWithPath } from "@mantine/dropzone"
import React, { useMemo } from "react"
import { API_URL } from "../../../shared/config"

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    width: "100%",
    height: "100%",
    padding: 8,
    borderRadius: theme.radius.md,
    borderWidth: 1,
    borderStyle: "solid",

    ".mantine-Dropzone-inner": {
      position: "relative",
      width: "100%",
      height: "100%",
      borderRadius: theme.radius.md,
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  bgImage: {
    width: "100%",
    height: "100%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },

  overlay: {
    pointerEvents: "all",
    userSelect: "auto",
  },
}))

type Props = {
  formValues: {
    onChange: (value: FileWithPath | undefined) => void
    value: string | FileWithPath | undefined
  }
  size?: "default" | "extra-small"
  maxSize?: number
} & Partial<DropzoneProps>

export function ImageDropzone(
  {
    formValues,
    maxSize = 10 * 1024 ** 2,
    size = "default",
    ...rest
  }: Props): React.JSX.Element {
  const theme = useMantineTheme()
  const { classes } = useStyles()

  const imageURL = useMemo(() => {
    if (formValues.value) {
      return formValues.value instanceof File
        ? URL.createObjectURL(formValues.value)
        : `${API_URL}/public${formValues.value}`.replace(/\\/g, "/")
    }

    return undefined
  }, [ formValues.value ])
console.log("sdfsdfsdsd", formValues, rest)
  const resetImage = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    formValues.onChange(undefined)
  }

  const onDrop = (files: Array<FileWithPath>) => {
    formValues.onChange(files[0])
  }

  const onLoad = () => {
    if (formValues.value instanceof File && imageURL) {
      URL.revokeObjectURL(imageURL)
    }
  }

  const { iconSize, majorTextSize, minorTextSize, spacing } = useMemo(() => {
    switch (size) {
      case "extra-small":
        return { iconSize: "1.5rem", majorTextSize: 10, minorTextSize: 10, spacing: 6 }
      case "default":
        return { iconSize: "3.2rem", majorTextSize: "lg", minorTextSize: "xs", spacing: "xl" }
    }
  }, [ size ])

  return (
    <Dropzone
      className={classes.wrapper}
      onDrop={onDrop}
      maxSize={maxSize}
      maxFiles={1}
      accept={IMAGE_MIME_TYPE}
      {...rest}
    >
      {imageURL ? (
          <BackgroundImage
            className={classes.bgImage}
            src={imageURL}
            onLoad={onLoad}
          >
            <Overlay opacity={0.3} center style={{ pointerEvents: "all", userSelect: "auto" }}>
              <ThemeIcon onClick={resetImage} variant="filled" radius="lg" size={iconSize} color="red">
                <IconX/>
              </ThemeIcon>
            </Overlay>
          </BackgroundImage>
        ) :
        (
          <Group p={10} position="center" spacing={spacing}>
            <Dropzone.Accept>
              <IconUpload
                size={iconSize}
                stroke={1.5}
                color={theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6]}
              />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconX
                size={iconSize}
                stroke={1.5}
                color={theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]}
              />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <IconPhoto
                size={iconSize}
                stroke={1.5}
              />
            </Dropzone.Idle>

            <div>
              {size === "default" && (
                <>
                  <Text size={majorTextSize}>Перетащите сюда изображение или нажмите для выбора файла</Text>
                  <Text size={minorTextSize} color="dimmed" mt={4}>
                    Допустимо только одно изображение, размер файла не должен превышать 10 Мб
                  </Text>
                </>
              )}
              {size === "extra-small" && (
                <>
                  <Text lh={1.3} align="center" size={minorTextSize} color="dimmed">
                    Не более 0.3 Мб
                  </Text>
                </>
              )}
            </div>
          </Group>
        )
      }
    </Dropzone>
  )
}
