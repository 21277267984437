import { lazy, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { Navigate, useLocation, useRoutes } from "react-router-dom"
import { useScrollToTop } from "shared/hooks"

import { MainLayout } from "../layouts/main"
import { dashboardRoutes } from "./dashboard"
import { authRoutes } from "./auth"
import { errorRoutes } from "./error"
import { mainRoutes } from "./main"
import { AuthStore } from "../store"
import { trainingRoutes } from "./training"

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import("pages").then(module => ({ default: module.HomePage })))

// ----------------------------------------------------------------------

export const Router = observer(function Router() {
  useScrollToTop()
  const location = useLocation()

  const { checkAuth } = AuthStore

  useEffect(() => {
    void checkAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    const hash = location.hash

    if (hash) {
      const element = document.getElementById(hash.replace("#", ""))
      element && element.scrollIntoView()
    }
  }, [ location ])

  return useRoutes([
    {
      path: "/",
      element: (
        <MainLayout>
          <HomePage/>
        </MainLayout>
      ),
    },

    ...authRoutes,
    ...mainRoutes,
    ...dashboardRoutes,
    // TODO: заглушка
    // ...trainingRoutes,

    ...errorRoutes,
    { path: "*", element: <Navigate to="/error/404" replace/> },
  ])
})
