import React, { forwardRef } from "react"
import { observer } from "mobx-react-lite"
import { Select, Center, type SelectProps } from "@mantine/core"
import { LandingColorSchemaType } from "../../model"
import { getLandingColors, getLandingColorsNames } from "../../utils"

const data = Object.values(LandingColorSchemaType).map(type => {
  const colorNames = getLandingColorsNames(type)
  return {
    type,
    value: type,
    label: `${colorNames.primary} - ${colorNames.secondary}`,
  }
})

type Props = {
  value: LandingColorSchemaType
  label?: string
} & Omit<SelectProps, "data" | "value"> & React.RefAttributes<HTMLInputElement>

export const LandingColorSchemaSelector = observer(function LandingColorSchemaSelector(props: Props) {
  const { value, ...rest } = props
  const currentColors = getLandingColors(value)
  return (
    <Select
      itemComponent={SelectItem}
      data={data}
      value={value}
      {...rest}
      styles={(theme) => ({
        dropdown: {
          borderRadius: theme.radius.md,
        },
        itemsWrapper: {
          gap: 4,
        },
        item: {
          transition: "all 0.154s ease",
          padding: 0,
          opacity: 0.3,
          "&[data-selected]": {
            "&, &:hover": {
              background: theme.colors.gray[3],
              color: theme.white,
              opacity: 1,
            },
          },
          "&[data-hovered]": {
            opacity: 1,
          },
        },
        input: {
          transition: "all 0.154s ease",
          background: theme.fn.gradient({
            from: currentColors.primary,
            to: currentColors.secondary,
          }),
          color: theme.white,
          opacity: 1,
        },
        rightSection: {
          "svg": {
            color: `${theme.white} !important`,
          },
        },
      })}
    />
  )
})

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  type: LandingColorSchemaType
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(function SelectItem({ type, ...others }: ItemProps, ref) {
    const colors = getLandingColors(type)
    const colorNames = getLandingColorsNames(type)

    return (
      <div ref={ref} {...others}>
        <Center
          sx={(theme) => ({
            borderRadius: 6,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 48,
            backgroundImage: theme.fn.gradient({
              from: colors.primary,
              to: colors.secondary,
            }),
            fontWeight: 600,
            fontSize: 16,
            color: theme.white,
          })}
        >
          {`${colorNames.primary} - ${colorNames.secondary}`}
        </Center>
      </div>
    )
  },
)
