import { type MantineColor } from "@mantine/core"
import { LandingColorSchemaType } from "../model"

export function getLandingColors(type: LandingColorSchemaType): {
  primary: MantineColor
  secondary: MantineColor
} {
  switch (type) {
    case LandingColorSchemaType.BLUE_INDIGO:
      return { primary: "blue", secondary: "indigo" }
    case LandingColorSchemaType.RED_PINK:
      return { primary: "red", secondary: "pink" }
    case LandingColorSchemaType.ORANGE_YELLOW:
      return { primary: "orange", secondary: "yellow" }
    case LandingColorSchemaType.GREEN_TEAL:
    default:
      return { primary: "green", secondary: "teal" }
  }
}

export function getLandingColorsNames(type: LandingColorSchemaType): {
  primary: string
  secondary: string
} {
  switch (type) {
    case LandingColorSchemaType.BLUE_INDIGO:
      return { primary: "Синий", secondary: "Индиго" }
    case LandingColorSchemaType.RED_PINK:
      return { primary: "Красный", secondary: "Розовый" }
    case LandingColorSchemaType.ORANGE_YELLOW:
      return { primary: "Оранжевый", secondary: "Желтый" }
    case LandingColorSchemaType.GREEN_TEAL:
    default:
      return { primary: "Зеленый", secondary: "Бриллиантовый" }
  }
}
