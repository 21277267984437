import React from "react"
import { observer } from "mobx-react-lite"
import { AuthStore } from "../store"
import { ErrorView } from "../../shared/ui/errorView"
import { type UserRole } from "../../shared/types/user"

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
  roles: Array<UserRole>
};

export const RoleBasedGuard = observer(function RoleBasedGuard({ children, roles }: Props) {
    const { user } = AuthStore

    if (typeof roles !== "undefined" && user && !roles.includes(user.role)) {
      return (
        <ErrorView
          label="403"
          title="Доступ запрещен"
          description="Вы пытаетесь попасть на страницу, к которой у вас нет доступа. Если вы считаете, что произошла ошибка,
        пожалуйста, свяжитесь с администрацией."
        />
      )
    }

    return <>{children}</>
  },
)
