import { makeAutoObservable, runInAction, reaction } from "mobx"
import {
  getInstructorById,
  getInstructors,
  remove,
  update,
  create,
  toggleInstructorPublish, updateMany,
} from "shared/api/instructors"
import {
  type Instructor,
  type InstructorParams,
} from "shared/types/instructor"
import { umbrellaException } from "../../shared/lib/umbrellaException"

// ----------------------------------------------------------------------

class InstructorStoreImpl {
  instructors: Array<Instructor> = []
  totalCount: number = 0

  publishedOnly: boolean = true
  search: string = ""

  orderLoading: boolean = false
  isLoading: boolean = false
  error: string | null = null

  constructor() {
    makeAutoObservable(this)

    reaction(
      (): [ string, boolean ] => [ this.search, this.publishedOnly ],
      ([ search, publishedOnly ]) => {
        void this.getAll({ search, publishedOnly })
      },
    )
  }

  setSearchValue(value: string) {
    this.search = value
  }

  toggleType = (checked: boolean) => {
    this.publishedOnly = checked
  }

  reorder = (from: number, to: number) => {
    runInAction(() => {
      const changedElements: Array<{ _id: string, order: number }> = []

      const extractedElement = this.instructors.splice(from, 1)[0]
      extractedElement.order = to
      changedElements.push({ _id: extractedElement._id, order: extractedElement.order })

      const firstPart = this.instructors.slice(0, to)
      const secondPart = this.instructors.slice(to)

      if (from > to) {
        secondPart.forEach((element, index) => {
          element.order = index + to + 1
          changedElements.push({ _id: element._id, order: element.order })
        })

      } else {
        firstPart.forEach((element, index) => {
          element.order = index
          changedElements.push({ _id: element._id, order: element.order })
        })
      }

      this.instructors = [ ...firstPart, extractedElement, ...secondPart ]

      void this.updateInstructorsOrder(changedElements)
    })
  }

  updateInstructorsOrder = async (list: Array<{ _id: string, order: number }>) => {
    try {
      this.orderLoading = true
      await updateMany(list)

      runInAction(() => {
        this.orderLoading = false
      })
    } catch (err) {
      this.error = umbrellaException(err)
      this.orderLoading = false
    }
  }

  getAll = async (
    {
      search,
      publishedOnly,
    }: Partial<InstructorParams> = {}) => {
    try {
      runInAction(() => {
        this.isLoading = true
        this.error = null
      })

      const { data } = await getInstructors({
        publishedOnly: publishedOnly ?? this.publishedOnly,
        search: search ?? this.search,
      })

      runInAction(() => {
        this.instructors = data.instructors
        this.totalCount = data.totalCount
        this.isLoading = false
      })
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }

  togglePublish = async (instructorId: string, published: boolean) => {
    try {
      runInAction(() => {
        this.isLoading = true
        this.error = null
      })

      const { data } = await toggleInstructorPublish(instructorId, published)

      runInAction(() => {
        const current = this.instructors.find(({ _id }) => _id === data._id)

        if (current) {
          current.published = data.published
        }

        this.isLoading = false
      })
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }

  createInstructor = async (body: FormData) => {
    try {
      this.isLoading = true
      this.error = null

      await create(body)

      runInAction(() => {
        this.isLoading = false
      })

      return true
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })

      return false
    }
  }

  updateInstructor = async (id: string, body: FormData) => {
    try {
      this.isLoading = true
      this.error = null

      const { data } = await update(id, body)

      runInAction(() => {
        this.instructors = this.instructors.map((instructor) => {
          return instructor._id === data._id ? data : instructor
        })

        this.isLoading = false
      })

      return true
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })

      return false
    }
  }

  loadInstructor = async (instructorId: string) => {
    try {
      this.isLoading = true
      this.error = null

      const { data } = await getInstructorById(instructorId)

      runInAction(() => {
        this.isLoading = false
      })

      return data
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }

  removeInstructor = async (instructorId: string) => {
    try {
      this.isLoading = true
      this.error = null

      await remove(instructorId)

      runInAction(() => {
        this.instructors = this.instructors.filter(
          (instructor) => instructor._id !== instructorId,
        )
        this.totalCount -= 1
        this.isLoading = false
      })
    } catch (err) {
      runInAction(() => {
        this.error = umbrellaException(err)
        this.isLoading = false
      })
    }
  }
}

export const InstructorStore = new InstructorStoreImpl()
