import { createStyles, Text, rem, Title } from "@mantine/core"
import React from "react"
import { SubscribeForm } from "./SubscribeForm"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    padding: rem(56),
    borderRadius: theme.radius.xl,
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
      padding: theme.spacing.sm,
    },
  },

  body: {
    width: "40%",

    [theme.fn.smallerThan("sm")]: {
      paddingRight: 0,
      width: "100%",
      marginTop: theme.spacing.xl,
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    lineHeight: 1,
    marginBottom: theme.spacing.lg,

    [theme.fn.smallerThan("sm")]: {
      textAlign: "center",
    },
  },
}))

export function Subscribe(): React.JSX.Element {
  const { classes } = useStyles()
  return (
    <div className={classes.wrapper}>
      <div className={classes.body}>
        <Title className={classes.title}>Поможем в выборе!</Title>
        <Text>
          Если у вас есть вопросы о формате или вы не знаете, что выбрать, оставьте свой номер — мы позвоним, чтобы
          ответить на все ваши вопросы.
        </Text>
      </div>
      <SubscribeForm/>
    </div>
  )
}
