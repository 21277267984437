import { runInAction } from "mobx"
import { type CourseDto, makeObservableStore } from "../../../common"

export interface CourseStore {
  isLoading: boolean
  error?: string
  totalCount: number
  courses: Array<CourseDto>
  searchParams: {
    page: number
    perPage: number
    search: string | undefined
    publishedOnly: boolean
  }
}

export const courseStore = makeObservableStore<CourseStore>({
  isLoading: false,
  totalCount: 0,
  courses: [],
  searchParams: {
    page: 1,
    perPage: 9,
    search: undefined,
    publishedOnly: false,
  },
})

export function resetCourseStore(): void {
  console.log("Reset course store")

  runInAction(() => {
    courseStore.isLoading = false
    courseStore.error = undefined
    courseStore.totalCount = 0
    courseStore.courses = []
    courseStore.searchParams = {
      page: 1,
      perPage: 9,
      search: undefined,
      publishedOnly: false,
    }
  })
}
