import { observer } from "mobx-react-lite"
import { createStyles, Header, Burger, Container, rem } from "@mantine/core"
import { Logo } from "shared/ui/Logo"
import { DashboardNavbarStore } from "app/store"

// ----------------------------------------------------------------------

const useStyles = createStyles(() => ({
  inner: {
    height: rem(70),
    display: "flex",
    alignItems: "center",
  },

  logo: {
    marginRight: "auto",
  },
}))

// ----------------------------------------------------------------------

export const DashboardHeader = observer(function DashboardHeader() {
  const { classes } = useStyles()
  const toggleNavbar = () => {
    if (DashboardNavbarStore.isOpened) {
      document.body.classList.remove("no-scroll")
    } else {
      document.body.classList.add("no-scroll")
    }

    DashboardNavbarStore.setOpened(!DashboardNavbarStore.isOpened)
  }

  return (
    <Header height={70}>
      <Container size="xl">
        <div className={classes.inner}>
          <Logo className={classes.logo}/>
          <Burger opened={DashboardNavbarStore.isOpened} onClick={toggleNavbar} size="sm"/>
        </div>
      </Container>
    </Header>
  )
})
