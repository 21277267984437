const ROOTS = {
  AUTH: "/auth",
  DASHBOARD: "/dashboard",
  ERROR: "/error",
  DOCS: "/docs",
  ACADEMY: "https://academy.bodycoach.pro/",
}

const ACADEMY = {
  ROOT: `${ROOTS.ACADEMY}/training`,
  COURSES: `${ROOTS.ACADEMY}/training/category/platnye-obucheniya`,
  MARATHONS: `${ROOTS.ACADEMY}/training/category/besplatnoe-obuchenie`,
  WEBINARS: `${ROOTS.ACADEMY}/training/category/vebinary`,
  PROFESSIONS: `${ROOTS.ACADEMY}/training/category/professii`,
  CONFERENCES: `${ROOTS.ACADEMY}/training/category/konferencii`,
}

// ----------------------------------------------------------------------

export const PATHS = {
  ROOT: "/",

  SCHEDULE: "/schedule",
  BLOG: {
    ROOT: "/blog",
    DETAILS: (articleId: string): string => `/blog/${articleId}`,
  },
  INSTRUCTORS: "/instructors",
  ABOUT_ORGANIZATION: "/about-organization",

  AUTH: {
    LOGIN: `${ROOTS.AUTH}/login`,
    FORGOT_PASSWORD: `${ROOTS.AUTH}/forgot-password`,
    NEW_PASSWORD: `${ROOTS.AUTH}/new-password`,
  },

  ERROR: {
    403: `${ROOTS.ERROR}/403`,
    404: `${ROOTS.ERROR}/404`,
    500: `${ROOTS.ERROR}/500`,
  },

  TRAINING: {
    ROOT: "/training",
    COURSES: {
      ROOT: "/training/courses",
      DETAILS: (courseId: string, slug?: string): string => `/training/courses/${courseId}${slug ? `/${slug}` : ""}`,
    },
  },

  DASHBOARD: {
    BLOG: {
      ROOT: `${ROOTS.DASHBOARD}/blog`,
      CREATE: `${ROOTS.DASHBOARD}/blog/article`,
      EDIT: (articleId: string): string => `${ROOTS.DASHBOARD}/blog/article/${articleId}`,
    },
    TRAINING: {
      ROOT: `${ROOTS.DASHBOARD}/training`,
      COURSES: {
        ROOT: `${ROOTS.DASHBOARD}/training/courses`,
        CREATE: `${ROOTS.DASHBOARD}/training/courses/course`,
        EDIT: (courseId: string): string => `${ROOTS.DASHBOARD}/training/courses/course/${courseId}`,
      },
      // CONFERENCES: {
      //   ROOT: `${ROOTS.DASHBOARD}/training/conferences`,
      //   CREATE: `${ROOTS.DASHBOARD}/training/conferences/conference`,
      //   EDIT: (conferenceId: string): string => `${ROOTS.DASHBOARD}/training/conferences/conference/${conferenceId}`,
      // },
      // MARATHONS: {
      //   ROOT: `${ROOTS.DASHBOARD}/training/marathons`,
      //   CREATE: `${ROOTS.DASHBOARD}/training/marathons/marathon`,
      //   EDIT: (marathonId: string): string => `${ROOTS.DASHBOARD}/training/marathons/marathon/${marathonId}`,
      // },
      // WEBINARS: {
      //   ROOT: `${ROOTS.DASHBOARD}/training/webinars`,
      //   CREATE: `${ROOTS.DASHBOARD}/training/webinars/webinar`,
      //   EDIT: (webinarId: string): string => `${ROOTS.DASHBOARD}/training/webinars/webinar/${webinarId}`,
      // },
      // PROFESSIONS: {
      //   ROOT: `${ROOTS.DASHBOARD}/training/professions`,
      //   CREATE: `${ROOTS.DASHBOARD}/training/professions/profession`,
      //   EDIT: (professionId: string): string => `${ROOTS.DASHBOARD}/training/professions/profession/${professionId}`,
      // },
    },
    INSTRUCTORS: {
      ROOT: `${ROOTS.DASHBOARD}/instructors`,
      CREATE: `${ROOTS.DASHBOARD}/instructors/instructor`,
      EDIT: (instructorId: string): string => `${ROOTS.DASHBOARD}/instructors/instructor/${instructorId}`,
    },
    SCHEDULE: {
      ROOT: `${ROOTS.DASHBOARD}/schedule`,
      CREATE: `${ROOTS.DASHBOARD}/schedule/event`,
      EDIT: (scheduleEventId: string): string => `${ROOTS.DASHBOARD}/schedule/event/${scheduleEventId}`,
    },
    BANNERS: {
      ROOT: `${ROOTS.DASHBOARD}/banners`,
      CREATE: `${ROOTS.DASHBOARD}/banners/banner`,
      EDIT: (bannerId: string): string => `${ROOTS.DASHBOARD}/banners/banner/${bannerId}`,
    },
    ABOUT: {
      ROOT: `${ROOTS.DASHBOARD}/about`,
    },
  },

  DOCS: {
    ROOT: ROOTS.DOCS,

    COOKIES: `${ROOTS.DOCS}/cookies`,
    OFFER: `${ROOTS.DOCS}/offer`,
    PRIVACY_POLICY: `${ROOTS.DOCS}/privacy-policy`,
    AFFILIATE_PROGRAM: "/affiliate-program",
  },

  EXTERNAL: {
    ACADEMY: {
      ROOT: ROOTS.ACADEMY,

      ALL_DIRECTIONS: ACADEMY.ROOT,

      COURSES: {
        ROOT: ACADEMY.COURSES,

        HORMONAL_SYSTEM: `${ACADEMY.COURSES}/gormonalnaya-terapiya`,
        NUTRICIOLOGY: `${ACADEMY.COURSES}/nutriciologiya`,
        LFK_AND_REHABILITATION: `${ACADEMY.COURSES}/lfk-i-reabilitaciya`,
        TRAINING_PROCESS: `${ACADEMY.COURSES}/trenirovochnyy-process`,
        GROUP_DESTINATIONS: `${ACADEMY.COURSES}/instruktor-gruppovyh-programm`,
        PSYCHOLOGY_AND_SALES: `${ACADEMY.COURSES}/psihologiya-i-prodaghi`,
      },

      CONFERENCES: {
        ROOT: ACADEMY.CONFERENCES,
      },

      MARATHONS: {
        ROOT: ACADEMY.MARATHONS,
      },

      WEBINARS: {
        ROOT: ACADEMY.WEBINARS,
      },

      PROFESSIONS: {
        ROOT: ACADEMY.PROFESSIONS,
      },
    },
  },
}
