import { makeAutoObservable } from "mobx"

class DashboardNavbarStoreImpl {
  isOpened: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setOpened(state: boolean) {
    this.isOpened = state
  }
}

export const DashboardNavbarStore = new DashboardNavbarStoreImpl()
