import { createStyles } from "@mantine/core"
import { Header } from "widgets/header"
import React from "react"
import { observer } from "mobx-react-lite"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  rootWrapper: {
    boxSizing: "border-box",
    minWidth: 320,
    minHeight: "100vh",
  },

  wrapper: {
    display: "flex",
    boxSizing: "border-box",
  },

  main: {
    flex: 1,
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    boxSizing: "border-box",
    width: "100vw",
  },
}))

// ----------------------------------------------------------------------

export type Props = {
  children: React.ReactNode
};

export const CompactLayout = observer(function CompactLayout({ children }: Props) {
    const { classes } = useStyles()

    return (
      <section className={classes.rootWrapper}>
        <Header simple/>
        <section className={classes.wrapper}>
          <main className={classes.main}>{children}</main>
        </section>
      </section>
    )
  },
)
